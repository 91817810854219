import AreaAvaliacao from './AreaAvaliacao';

export default class Prova {
  #id;
  #ano;
  #urlSinteseArea;
  #urlPadraoResposta;
  #urlGabarito;
  #url;
  #areaAvaliacao;

  constructor(params) {
    this.#id = params.id;
    this.#ano = params.ano;
    this.#urlSinteseArea = params.urlSinteseArea;
    this.#urlPadraoResposta = params.urlPadraoResposta;
    this.#urlGabarito = params.urlGabarito;
    this.#url = params.url;
    this.#areaAvaliacao = new AreaAvaliacao(params.areaAvaliacao);
  }

  get id() {
    return this.#id;
  }

  get ano() {
    return this.#ano;
  }

  get urlSinteseArea() {
    return this.#urlSinteseArea;
  }

  get urlPadraoResposta() {
    return this.#urlPadraoResposta;
  }

  get urlGabarito() {
    return this.#urlGabarito;
  }

  get url() {
    return this.#url;
  }

  get areaAvaliacao() {
    return this.#areaAvaliacao;
  }
};
