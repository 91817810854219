import React, { useState } from 'react';

import style from './style.module.css';

import IconButton from '@mui/material/IconButton';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const PdfController = (props) => {
  const url = props.url;
  const paginaInicio = props.paginaInicio;
  const paginaFim = props.paginaFim;
  const numPages = paginaFim - paginaInicio + 1;
  const zoom = props.zoom;
  const setZoom = props.setZoom;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const setPageNumber = props.setPageNumber;

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
    setCurrentPageNumber(prevCurrentPageNumber => prevCurrentPageNumber + offset);
  };

  const nextPage = () => {
    changePage(1);
  };

  const prevPage = () => {
    changePage(-1);
  };

  const zoomIn = () => {
    setZoom(zoom * 1.1);
  }

  const zoomOut = () => {
    setZoom(zoom * 0.9);
  }

  return (
    <div className={style.container}>
      <span>Página {currentPageNumber} de {numPages}</span>
      <IconButton
        onClick={prevPage}
        disabled={currentPageNumber <= 1}
      >
        <NavigateBeforeIcon
          className={currentPageNumber <= 1 ? `${style.pdfControllerIcon} ${style.disabledIcon}` : style.pdfControllerIcon}
        />
      </IconButton>
      <IconButton
        onClick={nextPage}
        disabled={currentPageNumber >= numPages}
      >
        <NavigateNextIcon
          className={currentPageNumber >= numPages ? `${style.pdfControllerIcon} ${style.disabledIcon}` : style.pdfControllerIcon}
        />
      </IconButton>
      <IconButton
        onClick={zoomOut}
        disabled={zoom <= 0.8}
      >
        <ZoomOutIcon
          className={zoom <= 0.8 ? `${style.pdfControllerIcon} ${style.disabledIcon}` : style.pdfControllerIcon}
        />
      </IconButton>
      <IconButton
        onClick={zoomIn}
        disabled={zoom >= 1.5}
      >
        <ZoomInIcon
          className={zoom >= 1.5 ? `${style.pdfControllerIcon} ${style.disabledIcon}` : style.pdfControllerIcon}
        />
      </IconButton>
      <IconButton
        href={`${url}#page=${paginaInicio}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <OpenInNewIcon className={style.pdfControllerIcon} />
      </IconButton>
      {/*<div>
        <a
          className={style.questionLinkProva}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          VISUALIZAR PROVA COMPLETA
        </a>
      </div>*/}
    </div>
  );
};
