import React from "react";

import style from "./style.module.css";

export const Estatistica = ({ label, value }) => {
  const composeLabel = (value) => {
    if (value > 0) {
      return label;
    } else {
      return null;
    }
  };

  const composeValue = (value) => {
    if (value > 0) {
      return `${value}%`;
    } else {
      return 'Estatística não disponível no relatório do INEP';
    }
  };

  return (
    <div className={style.estatistica}>
      <div className={style.estatisticaValue}>
        {composeValue(value)}
      </div>
      <div className={style.estatisticaLabel}>
        {composeLabel(value)}
      </div>
    </div>
  );
};
