export default class Municipio {
  #id;
  #nome;
  #abreviacao;

  constructor(params) {
    this.#id = params.id;
    this.#nome = params.nome;
    this.#abreviacao = params.abreviacao;
  }

  get id() {
    return this.#id;
  }

  get nome() {
    return this.#nome;
  }

  get abreviacao() {
    return this.#abreviacao;
  }
};
