import logo from '../../assets/img/logo.png';

import style from './style.module.css';

export const HeaderLinks = () => {
  return (
    <div className={style.container}>
      <a
        href='/'
        alt='logo'
      >
        <img src={logo} alt='logo' className={style.logo} />
      </a>
      <div className={style.list}>
        <div className={style.listItem}>
          <a
            href='/#sobre'
            className={style.listItemLink}
          >
            O QUE É
          </a>
        </div>
        <div className={style.listItem}>
          <a
            href='/#como-usar'
            className={style.listItemLink}
          >
            COMO USAR
          </a>
        </div>
        <div className={style.listItem}>
          <a
            href='/#links-uteis'
            className={style.listItemLink}
          >
            LINKS ÚTEIS
          </a>
        </div>
      </div>
    </div>
  );
};
