import React, { useState } from 'react';
import { Page, Document, pdfjs } from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
//import 'react-pdf/dist/esm/Page/TextLayer.css';

import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import CircularProgress from '@mui/material/CircularProgress';

import { PdfController } from './PdfController/PdfController';
import { Alert } from '../Alert/Alert';
import style from './style.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export const PdfViewer = ({ file, paginaInicio, paginaFim }) => {
  const [pageNumber, setPageNumber] = useState(paginaInicio);
  const [zoom, setZoom] = useState(1.19);
  const [isLoading, setIsLoading] = useState(true);

  const loadingDocumentSuccessState = () => {
    setIsLoading(false);
  };

  const loadingDocumentState = () => {
    return (
      <Alert
        icon={<CircularProgress size={20} sx={{ color: '#03a9f4' }} />}
        state={'Carregando'}
        message={'Aguarde enquanto o PDF da questão carrega.'}
        style={{
          border: '1px solid #03a9f4',
          color: '#014361'
        }}
      />
    );
  };

  const loadingDocumentErrorState = () => {
    return (
      <Alert
        icon={<ErrorOutlineSharpIcon sx={{ color: '#ef5350' }} />}
        state={'Erro'}
        message={'Não foi possível carregar o PDF da questão.'}
        style={{
          border: '1px solid #ef5350',
          color: '#5f2120'
        }}
      />
    );
  };

  return (
    <div className={style.container}>
      {
        !isLoading &&
        <div className={style.documentController}>
          <PdfController
            url={file}
            paginaInicio={paginaInicio}
            paginaFim={paginaFim}
            setPageNumber={setPageNumber}
            zoom={zoom}
            setZoom={setZoom}
          />
        </div>
      }
      <div className={style.documentContainer}>
        <Document
          file={file}
          loading={loadingDocumentState}
          error={loadingDocumentErrorState}
          onLoadSuccess={loadingDocumentSuccessState}
          onLoadError={console.warn}
        >
          <Page
            className={style.page}
            scale={zoom}
            pageNumber={pageNumber}
          />
        </Document>
      </div>
    </div>
  );
};
