import CursoRepository from '../repository/CursoRepository';

export default class CursoController {
  #repository;

  constructor() {
    this.#repository = new CursoRepository();
  }

  async getCurso(id) {
    if (!id) {
      console.error('O ID do curso informado é inválido');
      return null;
    }

    return await this.#repository.getCurso(id);
  }

  async getCursos() {
    return await this.#repository.getCursos();
  }

  async getOpcoesCursos() {
    return Promise
      .all((await this.#repository.getCursos())
        .map(async (curso) => ({
          label: curso.nome,
          value: curso.id
        }))
      );
  }
};
