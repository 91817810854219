import Municipio from './Municipio';

export default class Curso {
  #id;
  #nome;
  #municipio;

  constructor(params) {
    this.#id = params.id;
    this.#nome = params.nome;
    this.#municipio = new Municipio(params.municipio);
  }

  get id() {
    return this.#id;
  }

  get nome() {
    return this.#nome;
  }

  get municipio() {
    return this.#municipio;
  }
};
