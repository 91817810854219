import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Button from '@mui/material/Button';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { Footer } from '../../components/Footer/Footer';
import { QuestionList } from '../../components/QuestionList/QuestionList';
import { LoadingAnimation } from '../../components/LoadingAnimation/LoadingAnimation';

import QuestaoController from '../../services/controller/QuestaoController';

import { useSearchData } from '../../context/SearchDataContext';

import style from './style.module.css';

export const Search = () => {
  const { searchData, setSearchData } = useSearchData();

  const [questoes, setQuestoes] = useState(null);
  const [totalResults, setTotalResults] = useState(null);
  const [lastRequest, setLastRequest] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);

  const questaoController = new QuestaoController();

  useEffect(() => {
    if (!searchData.updated) return;

    setSearchData(prevSearchData => ({
      ...prevSearchData,
      updated: false
    }));

    const tempQuestions = [];

    questaoController.getQuestoes(searchData)
      .then((response) => {
        setTotalResults(response.total);
        setLastRequest(response);
        setNextUrl(response.nextUrl);

        response
          .questoes
          .forEach((questao) => {
            tempQuestions.push(questao);
          });

        setQuestoes(tempQuestions);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.requested]);

  const loadMoreQuestions = () => {
    const newQuestions = questoes;

    questaoController.getNextQuestoes(searchData, lastRequest.nextUrl)
      .then(response => {
        response.questoes.forEach((questao) => {
          newQuestions.push(questao);
        });

        setQuestoes(newQuestions);
        setNextUrl(response.nextUrl);
        setLastRequest(response);
      });
  };

  return (
    <div className={style.container}>
      <div className={style.main}>
        <Helmet>
          <title>Resultados - Questiona UFSM</title>
          <meta
            httpEquiv='Content-Security-Policy'
            content='upgrade-insecure-requests'
          />
          <meta
            charSet='utf-8'
            name='viewport'
            content='width=device-width, initial-scale=1.0, shrink-to-fit=no'
          />
          { /*<meta
            httpEquiv='Content-Security-Policy'
            content='upgrade-insecure-requests'
  /> */ }

          <meta name='description' content='Some description.' />
          <meta property='og:title' content='Resultados - Questiona UFSM' />
          <meta property='og:image' content='path/to/image.jpg' />
          <link rel='canonical' href='#' />
        </Helmet>

        <div>
          {
            !questoes &&
            <LoadingAnimation
              message='Buscando questões...'
            />
          }
        </div>

        <div>
          {
            questoes &&
            questoes.length > 0 &&
            (
              <QuestionList questoes={questoes} totalResults={totalResults} />
            )
          }
        </div>
        {
          nextUrl &&
          (
            <div className={style.loadMoreButton}>
              <Button
                onClick={loadMoreQuestions}
                variant='outlined'
                endIcon={<MoreHorizIcon />}
              >
                Carregar mais questões
              </Button>
            </div>
          )
        }
        {
          questoes && !nextUrl &&
          (
            <div className={style.endMessage}>
              Todos os resultados foram exibidos!
            </div>
          )
        }
      </div>
      <Footer />
    </div>
  );
};
