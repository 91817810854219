import React, { useEffect } from 'react';
import lottie from 'lottie-web';

import loadingAnimation from './loadingAnimation.json';
import style from './style.module.css';

export const LoadingAnimation = ({ message }) => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#loadingAnimation'),
      animationData: loadingAnimation,
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    });
  }, []);

  return (
    <div className={style.loadingAnimation}>
      <div className={style.animationMessage}>
        {message}
      </div>
      <div
        id='loadingAnimation'
        className={style.animation}
      />
    </div>
  );
};
