import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Footer } from '../../components/Footer/Footer';
import { QuestionInfo } from '../../components/QuestionInfo/QuestionInfo';
import { LoadingAnimation } from '../../components/LoadingAnimation/LoadingAnimation';

import QuestaoController from '../../services/controller/QuestaoController';

import style from './style.module.css';

export const Question = () => {
  const [questao, setQuestao] = useState(null);
  const params = useParams();
  const id = params.id;

  const questaoController = new QuestaoController();

  useEffect(() => {
    questaoController.getQuestao(id)
      .then((questao) => {
        setQuestao(questao);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className={style.container}>
      <div className={style.main}>
        <Helmet>
          <title>Questão - Questiona UFSM</title>
          <meta
            httpEquiv='Content-Security-Policy'
            content='upgrade-insecure-requests'
          />
          <meta
            charSet='utf-8'
            name='viewport'
            content='width=device-width, initial-scale=1.0, shrink-to-fit=no'
          />
               { /*     <meta
            httpEquiv='Content-Security-Policy'
            content='upgrade-insecure-requests'
          /> */ }

          <meta name='description' content='Some description.' />
          <meta property='og:title' content='Questão - Questiona UFSM' />
          <meta property='og:image' content='path/to/image.jpg' />
          <link rel='canonical' href='#' />
        </Helmet>

        <div>
          {
            !questao &&
            <LoadingAnimation
              message='Carregando questão...'
            />
          }
        </div>

        {
          questao &&
          <QuestionInfo
            questao={questao}
          />
        }
      </div>

      <Footer />
    </div>
  );
};
