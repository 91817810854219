import ProvaSerializer from './ProvaSerializer';
import EstatisticaQuestaoSerializer from './EstatisticaQuestaoSerializer';
import CursoSerializer from './CursoSerializer';

export default class QuestaoSerializer {
  static deserialize(data) {
    return {
      id: data.id_questao,
      parteProva: data.parte_prova,
      tipo: data.tipo_questao,
      conteudo: data.conteudo,
      conteudoDetalhado: data.conteudo_detalhado,
      gabarito: data.gabarito,
      imagem: data.imagem,
      texto: data.texto,
      numero: data.num_questao ? data.num_questao.trim() : null,
      pagina: data.pagina,
      paginaInicio: data.pag_ini,
      paginaFim: data.pag_fim,

      prova: ProvaSerializer.deserialize(data.prova),

      estatisticas: data.estatistica ? data.estatistica
        .map(estatistica => ({
          ...EstatisticaQuestaoSerializer.deserialize(estatistica),
          curso: CursoSerializer.deserialize({
            id_proplan: estatistica.curso.id_curso,
            nome: estatistica.curso.nome_curso,
            nome_municipio: estatistica.curso.nome_municipio,
            abrev_municipio: estatistica.curso.abrev_municipio,
          })
        })) : null
    };
  }
};
