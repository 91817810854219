import { isEmpty } from 'lodash';

export const composeSearchQuery = (searchData) => {
  const { curso, ano, palavrasChave } = parseSearchData(searchData);

  let query = '/search';

  if (curso !== null) {
    query += `?curso=${curso}`;
  }

  if (ano !== null) {
    query += (query === '/search') ? `?ano=${ano}` : `&ano=${ano}`
  }

  if (palavrasChave !== null) {
    query += (query === '/search') ? `?palavrasChave=${palavrasChave}` : `&palavrasChave=${palavrasChave}`
  }

  return query;
};

export const composeApiQuery = (searchData) => {
  const { curso, ano, palavrasChave } = parseSearchData(searchData);
  const limit = 46;

  let query = '';

  if (curso !== null) {
    query = `?id_curso_ufsm=${curso}`;
  }

  if (ano !== null) {
    query = (isEmpty(query)) ? `?ano=${ano}` : query + `&ano=${ano}`
  }

  if (palavrasChave !== null) {
    query = (isEmpty(query)) ? `?q=${palavrasChave}` : query + `&q=${palavrasChave}`
  }

  return (isEmpty(query)) ? (query + `?limit=${limit}`) : (query + `&limit=${limit}`);
};

const parseSearchData = (searchData) => {
  return searchData ? {
    curso: searchData.curso ? searchData.curso.value : null,
    ano: searchData.ano ? searchData.ano.value : null,
    palavrasChave: isEmpty(searchData.palavrasChave) ? null : searchData.palavrasChave
  } : null;
};
