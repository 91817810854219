export default class EstatisticaQuestaoSerializer {
  static deserialize(data) {
    return {
      id: data.id,
      idEnade: data.id_enade,
      idQuestao: data.id_questao,
      acertosCurso: data.acertos_curso,
      acertosRegiao: data.acertos_regiao,
      acertosBrasil: data.acertos_brasil,
      acertosBrasilDiferenca: data.acertos_brasil_dif
    };
  }
};
