import React from 'react';
import { useNavigate } from 'react-router-dom';

import { QuestionType } from './QuestionType/QuestionType';
import { QuestionResult } from './QuestionResult/QuestionResult';
import { PdfViewer } from '../PdfViewer/PdfViewer';
import { Alert } from '../Alert/Alert';

import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { composeSearchQuery } from '../../utils/queryComposerUtil';

import { useSessionStorage } from '../../hooks/useSessionStorage';
import { useWindowSize } from '../../hooks/useWindowSize';

import style from './style.module.css';

export const QuestionInfo = (props) => {
  const { questao } = props;
  const windowSize = useWindowSize();

  const navigate = useNavigate();

  const [searchData] = useSessionStorage('searchData', {
    curso: null,
    ano: null,
    palavrasChave: ''
  });

  const initialSearchData = {
    ...searchData
  };

  const handleBack = () => {
    navigate(composeSearchQuery(initialSearchData));
  };

  const composeTituloQuestao = (questao) => {
    return `Área de avaliação: ${questao.prova.areaAvaliacao.nome}`;
  };

  const composeQuestionLink = (questao) => {
    const urlProva = questao.prova.url;
    const paginaInicio = questao.paginaInicio;
    return `${urlProva}#page=${paginaInicio}`;
  };

  const composeMobileViewMessageAlert = (questao) => {
    const link = composeQuestionLink(questao);
    return (
      <>
        Devido ao tamanho da tela do dispositivo que você está utilizando, a questão não poderá ser mostrada. Mas não fique triste, você ainda pode visualizá-la clicando <a href={link} target='_blank' rel='noopener noreferrer'>aqui</a>.
      </>
    );
  };

  return (
    <div className={style.container}>
      <div className={style.questionView}>
        <div className={style.questionLeftView}>
          <div className={style.questionLeftViewTexts}>
            <div className={style.teste}>
              <div>
                <IconButton onClick={handleBack}>
                  <ArrowCircleLeftOutlinedIcon
                    className={style.goBackIcon}
                  />
                </IconButton>
              </div>
              <div className={style.questionMainInfo}>
                <div className={style.questionTitle}>
                  {composeTituloQuestao(questao)}
                </div>
                <QuestionType questao={questao} />
              </div>
            </div>
          </div>
        </div>
        <div className={style.questionRightView}>
          <div className={style.pdfView}>
            {
              windowSize.width < 768
                ?
                (
                  <Alert
                    icon={<WarningAmberIcon sx={{ color: '#ff9800' }} />}
                    state={'Atenção'}
                    message={composeMobileViewMessageAlert(questao)}
                    style={{
                      border: '1px solid #ff9800',
                      color: '#663c00'
                    }}
                  />
                )
                :
                (
                  <PdfViewer
                    file={questao.prova.url}
                    paginaInicio={questao.paginaInicio}
                    paginaFim={questao.paginaFim}
                  />
                )
            }
          </div>
          <QuestionResult questao={questao} />
        </div>
      </div>
    </div>
  );
};
