import React from "react";

import style from "./style.module.css";

export const EstatisticaCurso = ({ label, value }) => {
  const formatLabel = (value) => {
    if (value >= 0) {
      return label;
    } else {
      return null;
    }
  };

  const formatValue = (value) => {
    if (value >= 0) {
      return `${value}%`;
    } else {
      return null;
    }
  };

  return (
    <div className={style.estatistica}>
      <div className={style.estatisticaValue}>
        {formatValue(value)}
      </div>
      <div className={style.estatisticaLabel}>
        {formatLabel(value)}
      </div>
    </div>
  );
};
