import React, { useState } from "react";

import style from './style.module.css';

import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const EstatisticaSlider = ({ estatisticas }) => {
  const [currentEstatisticaIndex, setCurrentEstatisticaIndex] = useState(0);
  const length = estatisticas.length;

  const nextEstatistica = () => {
    const offset = currentEstatisticaIndex === length - 1 ? 0 : currentEstatisticaIndex + 1;
    setCurrentEstatisticaIndex(offset);
  };

  const prevEstatistica = () => {
    const offset = currentEstatisticaIndex === 0 ? length - 1 : currentEstatisticaIndex - 1;
    setCurrentEstatisticaIndex(offset);
  };

  if (!estatisticas || !Array.isArray(estatisticas) || length <= 0) {
    return null;
  }

  return (
    <div className={style.container}>
      {
        estatisticas.map((estatistica, index) => {
          return (
            <div
              className={index === currentEstatisticaIndex ? `${style.slide} ${style.active}` : style.slide}
              key={index}
            >
              {
                index === currentEstatisticaIndex && estatistica
              }
            </div>
          );
        })
      }
      <div className={style.buttons}>
        <IconButton
          onClick={prevEstatistica}
          disabled={length === 1}
        >
          <NavigateBeforeIcon
            className={length === 1 ? `${style.button} ${style.disabledButton}` : style.button}
          />
        </IconButton>
        <IconButton
          onClick={nextEstatistica}
          disabled={length === 1}
        >
          <NavigateNextIcon
            className={length === 1 ? `${style.button} ${style.disabledButton}` : style.button}
          />
        </IconButton>
      </div>
    </div>
  );
};
