import { provider } from '../api/provider';

export default class Repository {
  #resource;
  #Model;
  #Serializer;

  constructor(resource, Model, Serializer) {
    if (this.constructor === Repository) {
      throw new Error("Abstract classes can't be instantiated.");
    } else {
      this.#resource = resource;
      this.#Model = Model;
      this.#Serializer = Serializer;
    }
  }

  async _get(query) {
    return await provider.get(this.#resource, query);
  }

  async _getByParam(param) {
    const response = await provider.getByParam(this.#resource, param);
    const deserializedData = this.#Serializer.deserialize(response.data);
    const data = new this.#Model(deserializedData);
    return data;
  }

  async _getAll() {
    const response = await provider.getAll(this.#resource);
    const deserializedData = response.data.map(this.#Serializer.deserialize);
    const data = deserializedData.map(params => new this.#Model(params));
    return data;
  }
};
