import ProvaRepository from '../repository/ProvaRepository';

export default class ProvaController {
  #repository;

  constructor() {
    this.#repository = new ProvaRepository();
  }

  async getProva(id) {
    if (!id) {
      console.error('O ID da prova informado é inválido');
      return null;
    }

    return await this.#repository.getProva(id);
  }

  async getProvas() {
    return await this.#repository.getProvas();
  }

  async getAnosProvas() {
    return await this.#repository.getAnosProvas();
  }

  async getAnosProvasByIdCursoUFSM(idCursoUFSM) {
    if (!idCursoUFSM) {
      console.error('O ID do curso da UFSM informado é inválido');
      return null;
    }

    return await this.#repository.getAnosProvasByIdCursoUFSM(idCursoUFSM);
  }

  async getOpcoesAnos(opcaoCurso) {
    const idCursoUFSM = opcaoCurso ? opcaoCurso.value : null;
    const anos = (idCursoUFSM) ? await this.getAnosProvasByIdCursoUFSM(idCursoUFSM) : await this.getAnosProvas();

    return anos
      .map(ano => ({
        label: ano,
        value: ano
      }));
  }
};
