import style from './style.module.css';

export const Footer = () => {
  return (
    <footer className={style.container}>
      <div className={style.content}>
        Projeto desenvolvido na disciplina de Laboratório de Projetos Integrados
      </div>
    </footer>
  );
};
