import Repository from './Repository';
import Curso from '../model/Curso';
import CursoSerializer from '../serializer/CursoSerializer';

const resource = 'cursos';

export default class CursoRepository extends Repository {
  constructor() {
    super(resource, Curso, CursoSerializer);
  }

  async getCurso(id) {
    const curso = await this._getByParam(id);
    return curso;
  }

  async getCursos() {
    const cursos = await this._getAll();
    return cursos;
  }
};
