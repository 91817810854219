export default class AreaAvaliacao {
  #id;
  #nome;

  constructor(params) {
    this.#id = params.id;
    this.#nome = params.nome;
  }

  get id() {
    return this.#id;
  }

  get nome() {
    return this.#nome;
  }
};
