import React from 'react';

import style from './style.module.css';

export const Alert = (props) => {
  const icon = props.icon;
  const state = props.state;
  const message = props.message;

  return (
    <div className={style.container} style={props.style}>
      <div className={style.icon}>
        {icon}
      </div>
      <div className={style.content}>
        <div className={style.title}>
          {state}
        </div>
        <div className={style.message}>
          {message}
        </div>
      </div>
    </div>
  );
};
