import { Helmet } from 'react-helmet';

import { About } from '../../components/About/About';
import { Footer } from '../../components/Footer/Footer';

import style from './style.module.css';

export const Home = () => {
  return (
    <div className={style.container}>
      <div className={style.main}>
        <Helmet>
          <title>Home - Questiona UFSM</title>

          {/*<meta
            httpEquiv='Content-Security-Policy'
            content='upgrade-insecure-requests'
  />*/}

          <meta
            httpEquiv='Content-Security-Policy'
            content='upgrade-insecure-requests'
          />
          <meta
            charSet='utf-8'
            name='viewport'
            content='width=device-width, initial-scale=1.0, shrink-to-fit=no'
          />
          <meta name='description' content='Some description.' />
          <meta property='og:title' content='Home - Questiona UFSM' />
          <meta property='og:image' content='path/to/image.jpg' />
          <link rel='canonical' href='#' />
        </Helmet>

        <About />
      </div>

      <Footer className={style.footer} />
    </div>
  );
};
