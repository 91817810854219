import QuestaoSerializer from './QuestaoSerializer';

export default class QuestoesSerializer {
  static deserialize(data) {
    return {
      questoes: data.data ? data.data.map(QuestaoSerializer.deserialize) : [],
      prevUrl: data.prev_url,
      nextUrl: data.next_url,
      total: data.total,
      currentPageNumber: data.current_page,
      currentPageUrl: data.current_page_url
    };
  }
};
