import { provider } from '../api/provider';
import Repository from './Repository';
import Questao from '../model/Questao';
import Questoes from '../model/Questoes';
import QuestaoSerializer from '../serializer/QuestaoSerializer';
import QuestoesSerializer from '../serializer/QuestoesSerializer';

import { composeApiQuery } from '../../utils/queryComposerUtil';

const resource = 'questoes';

export default class QuestaoRepository extends Repository {
  constructor() {
    super(resource, Questao, QuestaoSerializer);
  }

  async getQuestao(id) {
    const response = await provider.getByParam(resource, id);
    const deserializedQuestao = QuestaoSerializer.deserialize({
      ...response.data.questao,
      estatistica: response.data.estatistica
    });
    const questao = new Questao(deserializedQuestao);
    return questao;
  }

  async getQuestoes(searchData) {
    const query = composeApiQuery(searchData);
    const response = await this._get(query);
    const deserializedData = QuestoesSerializer.deserialize(response.data);
    const questoes = new Questoes(deserializedData);
    return questoes;
  }

  async getNextQuestoes(searchData, nextUrl) {
    const nextPage = nextUrl.split('?').pop();
    const query = `${composeApiQuery(searchData)}&${nextPage}`;
    const response = await this._get(query);
    const deserializedData = QuestoesSerializer.deserialize(response.data);
    const nextQuestoes = new Questoes(deserializedData);
    return nextQuestoes;
  }
};
