import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Header } from './components/Header/Header';
import { HeaderLinks } from './components/HeaderLinks/HeaderLinks';
import { SearchHeader } from './components/SearchHeader/SearchHeader';

import { Home } from './pages/Home/Home';
import { Search } from './pages/Search/Search';
import { Question } from './pages/Question/Question';

import { SearchDataProvider } from './context/SearchDataContext';

import './App.css';

export const App = () => {
  return (
    <SearchDataProvider>
      <Router>
        <Header />
        <HeaderLinks />
        <SearchHeader />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/search' element={<Search />} />
          <Route path='/question/:id' element={<Question />} />
        </Routes>
      </Router>
    </SearchDataProvider>
  );
};
