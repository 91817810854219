import { api } from './api';

const get = async (resource, query) => {
  let response = null;

  try {
    response = await api.get(`${resource}${query}`);
  } catch (error) {
    console.error(error);
  }

  return response;
};

const getByParam = async (resource, param) => {
  let response = null;

  try {
    response = await api.get(`${resource}/${param}`);
  } catch (error) {
    console.error(error);
  }

  return response;
};

const getAll = async (resource) => {
  let response = null;

  try {
    response = await api.get(resource);
  } catch (error) {
    console.error(error);
  }

  return response;
};

export const provider = {
  get: get,
  getByParam: getByParam,
  getAll: getAll
};
