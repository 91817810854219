import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import SearchController from '../../services/controller/SearchController';

import { useSearchData } from '../../context/SearchDataContext';

import { useQueryParams } from '../../hooks/useQueryParams';
import { usePathname } from '../../hooks/usePathname';

import { composeSearchQuery } from '../../utils/queryComposerUtil';

import style from './style.module.css';

export const SearchHeader = () => {
  const navigate = useNavigate();
  const { isSearchPage } = usePathname();
  const queryParams = useQueryParams(['curso', 'ano', 'palavrasChave']);
  const { searchData, setSearchData } = useSearchData();

  const [opcoesCursos, setOpcoesCursos] = useState([]);
  const [opcoesAnosProvas, setOpcoesAnosProvas] = useState([]);

  const searchController = new SearchController();

  useEffect(() => {
    searchController.getOpcoesCursos()
      .then(cursos => {
        setOpcoesCursos(cursos);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let opcaoCurso = null;

    if (isSearchPage) {
      if (queryParams.curso) {
        opcaoCurso = { value: queryParams.curso };
      }
    } else {
      opcaoCurso = searchData.curso;
    }

    searchController.getOpcoesAnos(opcaoCurso)
      .then(anos => {
        setOpcoesAnosProvas(anos);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSearchPage) {
      if (opcoesAnosProvas.length === 0) return;

      const currentSearchData = {};

      if (queryParams.curso) {
        if (searchData.curso && searchData.curso.value === queryParams.curso) {
          currentSearchData.curso = searchData.curso;
        } else {
          const curso = opcoesCursos.find(curso => curso.value === queryParams.curso.trim());
          currentSearchData.curso = curso ? curso : null;
        }
      } else {
        currentSearchData.curso = null;
      }

      currentSearchData.ano = queryParams.ano ? ({
        label: Number(queryParams.ano),
        value: Number(queryParams.ano)
      }) : null;

      currentSearchData.palavrasChave = queryParams.palavrasChave ? queryParams.palavrasChave : '';

      setSearchData(prevSearchData => ({
        ...prevSearchData,
        ...currentSearchData,
        requested: !prevSearchData.requested,
        updated: true
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, opcoesCursos]);

  const onChangeCurso = (item) => {
    searchController.getOpcoesAnos(item)
      .then(anos =>
        setOpcoesAnosProvas(anos)
      );

    setSearchData(prevSearchData => ({
      ...prevSearchData,
      curso: item,
      ano: null
    }));
  };

  const onChangeAno = (item) => {
    setSearchData(prevSearchData => ({
      ...prevSearchData,
      ano: item
    }));
  };

  const onChangePalavrasChave = (event) => {
    setSearchData(prevSearchData => ({
      ...prevSearchData,
      palavrasChave: event.target.value
    }));
  };

  const setSearchQuery = () => {
    setSearchData(prevSearchData => ({
      ...prevSearchData,
      requested: !prevSearchData.requested,
      updated: true
    }));

    navigate(composeSearchQuery(searchData));
  };

  return (
    <div className={style.searchBarContainer}>
      <div className={style.inputs}>
        <TextField
          value={searchData.palavrasChave}
          className={style.palavrasChaveInput}
          id='palavras-chave-input'
          label='Palavras-chave'
          placeholder='Pesquise por palavras-chave'
          type='search'
          onChange={onChangePalavrasChave}
          variant='filled'
        />
        <div className={style.selects}>
          <Box className={style.select}>
            <Select
              value={searchData.curso}
              options={opcoesCursos}
              onChange={onChangeCurso}
              placeholder={'Selecionar curso'}
              className='select_input'
              isClearable={true}
            />
          </Box>
          <Box className={style.select}>
            <Select
              value={searchData.ano}
              options={opcoesAnosProvas}
              onChange={onChangeAno}
              placeholder={'Selecionar ano'}
              className='select_input'
              isClearable={true}
            />
          </Box>
        </div>
      </div>
      <IconButton onClick={setSearchQuery}>
        <SearchIcon sx={{ fontSize: 40 }} />
      </IconButton>
    </div>
  );
};
