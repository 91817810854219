import AreaAvaliacaoSerializer from "./AreaAvaliacaoSerializer";

export default class ProvaSerializer {
  static deserialize(data) {
    return {
      id: data.id_prova,
      ano: data.ano,
      urlSinteseArea: data.url_sintese_area,
      urlPadraoResposta: data.url_padrao_resposta,
      urlGabarito: data.url_gabarito,
      url: data.url_prova,
      areaAvaliacao: AreaAvaliacaoSerializer.deserialize(data.area_avaliacao)
    };
  }
};
