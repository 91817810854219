import Prova from './Prova';
import EstatisticaQuestao from './EstatisticaQuestao';
import Curso from './Curso';

export default class Questao {
  #id;
  #parteProva;
  #tipo;
  #conteudo;
  #conteudoDetalhado;
  #gabarito;
  #imagem;
  #texto;
  #pagina;
  #numero;
  #paginaInicio;
  #paginaFim;
  #prova;
  #estatisticas;

  constructor(params) {
    this.#id = params.id;
    this.#parteProva = params.parteProva;
    this.#tipo = params.tipo;
    this.#conteudo = params.conteudo;
    this.#conteudoDetalhado = params.conteudoDetalhado;
    this.#gabarito = params.gabarito;
    this.#imagem = params.imagem;
    this.#texto = params.texto;
    this.#numero = params.numero;
    this.#pagina = params.pagina;
    this.#paginaInicio = params.paginaInicio;
    this.#paginaFim = params.paginaFim;

    this.#prova = new Prova(params.prova);

    this.#estatisticas = params.estatisticas ? params.estatisticas
      .map(estatistica => ({
        ...new EstatisticaQuestao(estatistica).toJSON(),
        curso: new Curso(estatistica.curso)
      })) : null;
  }

  get id() {
    return this.#id;
  }

  get parteProva() {
    return this.#parteProva;
  }

  get tipo() {
    return this.#tipo;
  }

  get conteudo() {
    return this.#conteudo;
  }

  get conteudoDetalhado() {
    return this.#conteudoDetalhado;
  }

  get gabarito() {
    return this.#gabarito;
  }

  get imagem() {
    return this.#imagem;
  }

  get texto() {
    return this.#texto;
  }

  get numero() {
    return this.#numero;
  }

  get pagina() {
    return this.#pagina;
  }

  get paginaInicio() {
    return this.#paginaInicio;
  }

  get paginaFim() {
    return this.#paginaFim;
  }

  get prova() {
    return this.#prova;
  }

  get estatisticas() {
    return this.#estatisticas;
  }

  hasEstatisticas() {
    return this.#estatisticas && Array.isArray(this.#estatisticas) && this.#estatisticas.length > 0;
  }
};
