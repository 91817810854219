import React from "react";

import style from "./style.module.css";

export const Gabarito = ({ gabarito }) => {
  const isUrl = (url) => {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }

    return true;
  };

  return (
    <div className={style.container}>
      <div className={style.title}>
        Gabarito
      </div>
      <div className={style.gabarito}>
        {
          isUrl(gabarito) ?
            (
              <a
                href={gabarito}
                target="_blank"
                rel="noopener noreferrer"
              >
                LINK
              </a>
            )
            :
            (
              gabarito ? gabarito.toUpperCase() : null
            )
        }
      </div>
    </div>
  );
};
