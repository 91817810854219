import Repository from './Repository';
import Prova from '../model/Prova';
import ProvaSerializer from '../serializer/ProvaSerializer';

const resource = 'provas';

export default class ProvaRepository extends Repository {
  constructor() {
    super(resource, Prova, ProvaSerializer);
  }

  async getProva(id) {
    const prova = await this._getByParam(id);
    return prova;
  }

  async getProvas() {
    const provas = await this._getAll();
    return provas;
  }

  async getAnosProvas() {
    const query = `/anos`;
    const response = await this._get(query);
    const anosProvas = response.data;
    return anosProvas;
  }

  async getAnosProvasByIdCursoUFSM(idCursoUFSM) {
    const query = `/${idCursoUFSM}/anos`;
    const response = await this._get(query);
    const anosProvas = response.data;
    return anosProvas;
  }
};
