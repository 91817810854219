import MunicipioSerializer from './MunicipioSerializer';

export default class CursoSerializer {
  static deserialize(data) {
    return {
      id: data.id_proplan ? data.id_proplan.trim() : null,
      nome: data.nome ? data.nome.trim() : null,
      municipio: MunicipioSerializer.deserialize({
        id_municipio: data.id_municipio ?? null,
        nome: data.nome_municipio ?? null,
        abrev: data.abrev_municipio ?? null
      })
    };
  }
};
