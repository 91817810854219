import React, { createContext, useContext } from 'react';

import { useSessionStorage } from '../hooks/useSessionStorage';

const SearchDataContext = createContext();

export const SearchDataProvider = ({ children }) => {
  const [searchData, setSearchData] = useSessionStorage('searchData', {
    curso: null,
    ano: null,
    palavrasChave: '',
    requested: false,
    updated: false
  });

  return (
    <SearchDataContext.Provider
      value={{ searchData, setSearchData }}
    >
      {children}
    </SearchDataContext.Provider>
  );
};

export const useSearchData = () => {
  const { searchData, setSearchData } = useContext(SearchDataContext);
  return { searchData, setSearchData };
};
