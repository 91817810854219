import logo from '../../assets/img/monograma.png';

export const Header = () => {
  return (
    <header style={{ background: '#005C8B', margin: '0px' }}>
      <div className='container'>
        <a href='https://ufsm.br' alt='Brasão da UFSM'>
          <img src={logo} alt='logo' />
        </a>
      </div>
    </header>
  );
};
