import QuestaoRepository from '../repository/QuestaoRepository';

export default class QuestaoController {
  #repository;

  constructor() {
    this.#repository = new QuestaoRepository();
  }

  async getQuestao(id) {
    if (!id) {
      console.error('O ID da questão informado é inválido');
      return null;
    }

    return await this.#repository.getQuestao(id);
  }

  async getQuestoes(searchData) {
    return await this.#repository.getQuestoes(searchData);
  }

  async getNextQuestoes(searchData, nextUrl) {
    return await this.#repository.getNextQuestoes(searchData, nextUrl);
  }
};
