import React from 'react';

import Chip from '@mui/material/Chip';

import style from './style.module.css';

export const QuestionType = (props) => {
  const { questao } = props;

  const composeTipoQuestaoLabel = (tipoQuestao) => {
    switch (tipoQuestao) {
      case 'O':
        return 'Objetiva';
      case 'M':
        return 'Múltipla escolha';
      case 'D':
        return 'Discursiva';
      default:
        return '';
    }
  };

  const composeParteProvaLabel = (parteProva) => {
    return parteProva === 'G' ? 'Geral' : 'Específica';
  };

  return (
    <div className={style.container}>
      <Chip
        className={style.chip}
        label={`Questão ${questao.numero}`}
      />
      <Chip
        className={style.chip}
        label={composeParteProvaLabel(questao.parteProva)}
      />
      <Chip
        className={style.chip}
        label={composeTipoQuestaoLabel(questao.tipo)}
      />
      <Chip
        className={style.chip}
        label={`Enade ${questao.prova.ano}`}
      />
    </div>
  );
};
