import React from 'react';

import { Gabarito } from '../Gabarito/Gabarito';
import { Estatistica } from '../Estatistica/Estatistica';
import { EstatisticaCurso } from '../EstatisticaCurso/EstatisticaCurso';
import { EstatisticaSlider } from '../EstatisticaSlider/EstatisticaSlider';

import style from './style.module.css';

export const QuestionResult = ({ questao }) => {
  const estatisticas = questao.estatisticas;
  const gabarito = questao.gabarito;

  const composeAcertosCursoLabel = (estatistica) => {
    const idCurso = estatistica.curso.id;
    const nomeCurso = estatistica.curso.nome;
    const abreviacaoMunicipioCurso = estatistica.curso.municipio.abreviacao;
    return `acertos no curso de ${nomeCurso} (${idCurso}) - ${abreviacaoMunicipioCurso}`;
  };

  const composeAcertosRegiaoLabel = () => {
    return 'acertos na região';
  };

  const composeAcertosBrasilLabel = () => {
    return 'acertos no Brasil';
  };

  const getSliderData = () => {
    const estatisticasForSlider = [];

    if (estatisticas && estatisticas.length > 0) {
      estatisticasForSlider.push(
        ...estatisticas
          .filter(estatistica => estatistica.acertosCurso >= 0)
          .map((estatistica, index) =>
            <EstatisticaCurso
              key={index}
              label={composeAcertosCursoLabel(estatistica)}
              value={estatistica.acertosCurso}
            />
          )
      );
    }

    return estatisticasForSlider;
  };

  if (!questao.hasEstatisticas()) {
    return null;
  }

  return (
    <div className={style.container}>
      <Gabarito className={style.result} gabarito={gabarito} />
      <EstatisticaSlider
        className={style.result}
        estatisticas={getSliderData()}
      />
      {
        estatisticas[0].acertosRegiao > 0 &&
        <Estatistica
          className={style.result}
          label={composeAcertosRegiaoLabel()}
          value={estatisticas[0].acertosRegiao}
        />
      }
      {
        estatisticas[0].acertosBrasil > 0 &&
        <Estatistica
          className={style.result}
          label={composeAcertosBrasilLabel()}
          value={estatisticas[0].acertosBrasil}
        />
      }
    </div>
  );
};
