import comoUsar from '../../assets/img/como_usar.png';
import sobre from '../../assets/img/sobre.png';

import style from './style.module.css';

export const About = () => {
  return (
    <div className={style.container}>
      <div className={style.cards}>
        <div className={style.card} id='sobre'>
          <img className={style.cardImage} src={sobre} alt='sobre' />
          <div className={style.cardContainer}>
            <div className={style.cardTitle}>
              O QUE É
            </div>
            <div className={style.cardContent}>
              <div className={style.cardDescription}>
                O Questiona é a plataforma da UFSM que disponibiliza as questões
                do Exame Nacional de Desempenho dos Estudantes (ENADE) através
                de busca por área ou palavras-chaves.
              </div>
              <div className={style.cardDescription}>
                Foi criado para facilitar o reconhecimento de temas e
                disciplinas que são abordados no Exame e contribuir para a
                elaboração de planos de aula e avaliações. O Questiona existe
                para aproximar docentes e discentes do sistema de avaliação da
                qualidade do ensino superior no Brasil, a fim de melhorar o
                desempenho dos alunos na prova, os índices da Instituição e o
                reconhecimento dos cursos.
              </div>
              <div className={style.cardDescription}>
                É uma estratégia de apoio para os docentes de cursos que
                realizam o ENADE e aos discentes desses cursos para que possam
                conhecer e se familiarizar com a estrutura da prova. O
                Questiona tem acesso aberto e pode ser explorado por qualquer
                interessado em conhecer melhor o ENADE.
              </div>
            </div>
          </div>
        </div>
        <div className={style.card} id='como-usar'>
          <img className={style.cardImage} src={comoUsar} alt='como-usar' />
          <div className={style.cardContainer}>
            <div className={style.cardTitle}>
              COMO USAR
            </div>
            <div className={style.cardDescription}>
              Acesse o Questiona sempre que quiser consultar conteúdos e
              modelos de provas aplicadas nos anos anteriores.
              <br />
              <br />
              No cabeçalho da página você pode filtrar o conteúdo mais
              adequado para o que precisa, selecionando curso, ano de
              aplicação e palavra chave, que pode ser o conteúdo ou disciplina
              pelo qual você está buscando. O resultado mostrará as provas que
              contenham alguma questão relacionada ao conteúdo que você está
              buscando. <br />
              <br />
              As questões e conteúdos das provas de anos anteriores podem
              orientar a elaboração e o planejamento de aulas, aproximando
              docentes e discentes da estrutura de uma das principais
              ferramentas de avaliação da qualidade do ensino superior no
              Brasil.
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className={style.links} id='links-uteis'>
        <div className={style.linksTitle}>
          LINKS ÚTEIS
        </div>
        <div className={style.linksContainer}>
          <div className={style.link}>
            <a
              href='http://enade.inep.gov.br/enade/#!/index'
              target='_blank'
              rel='noopener noreferrer'
            >
              ENADE BRASIL
            </a>
          </div>
          <div className={style.link}>
            <a
              href='https://www.ufsm.br/pro-reitorias/prograd/category/enade/'
              target='_blank'
              rel='noopener noreferrer'
            >
              ENADE UFSM
            </a>
          </div>
        </div>
      </div>
    </div >
  );
};
