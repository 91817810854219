import Questao from './Questao';

export default class Questoes {
  #questoes;
  #prevUrl;
  #nextUrl;
  #total;
  #currentPageNumber;
  #currentPageUrl;

  constructor(params) {
    this.#questoes = params.questoes ? params.questoes.map(questao => new Questao(questao)) : null;
    this.#prevUrl = params.prevUrl;
    this.#nextUrl = params.nextUrl;
    this.#total = params.total;
    this.#currentPageNumber = params.currentPageNumber;
    this.#currentPageUrl = params.currentPageUrl;
  }

  get questoes() {
    return this.#questoes;
  }

  get prevUrl() {
    return this.#prevUrl;
  }

  get nextUrl() {
    return this.#nextUrl;
  }

  get total() {
    return this.#total;
  }

  get currentPageNumber() {
    return this.#currentPageNumber;
  }

  get currentPageUrl() {
    return this.#currentPageUrl;
  }
};
