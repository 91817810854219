export default class EstatisticaQuestao {
  #id;
  #idEnade;
  #idQuestao;
  #acertosCurso;
  #acertosRegiao;
  #acertosBrasil;
  #acertosBrasilDiferenca;

  constructor(params) {
    this.#id = params.id;
    this.#idEnade = params.idEnade;
    this.#idQuestao = params.idQuestao;
    this.#acertosCurso = params.acertosCurso;
    this.#acertosRegiao = params.acertosRegiao;
    this.#acertosBrasil = params.acertosBrasil;
    this.#acertosBrasilDiferenca = params.acertosBrasilDiferenca;
  }

  toJSON() {
    return {
      id: this.#id,
      idEnade: this.#idEnade,
      idQuestao: this.#idQuestao,
      acertosCurso: this.#acertosCurso,
      acertosRegiao:this.#acertosRegiao,
      acertosBrasil: this.#acertosBrasil,
      acertosBrasilDiferenca: this.#acertosBrasilDiferenca
    };
  }

  get id() {
    return this.#id;
  }

  get idEnade() {
    return this.#idEnade;
  }

  get idQuestao() {
    return this.#idQuestao;
  }

  get acertosCurso() {
    return this.#acertosCurso;
  }

  get acertosRegiao() {
    return this.#acertosRegiao;
  }

  get acertosBrasil() {
    return this.#acertosBrasil;
  }

  get acertosBrasilDiferenca() {
    return this.#acertosBrasilDiferenca;
  }
};
