import { useNavigate } from 'react-router-dom';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import style from './style.module.css';

export const QuestionList = (props) => {
  const navigate = useNavigate();

  const { questoes, totalResults } = props;

  const goToQuestion = (id) => {
    navigate(`/question/${id}`);
  };

  const composeConteudoDetalhado = (conteudoDetalhado, count) => {
    if (conteudoDetalhado) {
      const result = conteudoDetalhado.replace(/(\r\n|\n|\r)/gm, ' ').trim();

      if (conteudoDetalhado.length > count) {
        return `${result.slice(0, count).trim()}...`;
      } else {
        return result;
      }
    } else {
      return null;
    }
  };

  const composeResultado = (resultCount) => {
    switch (resultCount) {
      case 0:
        return `Não há resultados para a sua busca`;
      case 1:
        return `Foi encontrado apenas 1 resultado para a sua busca`;
      default:
        return `Foram encontrados ${resultCount} resultados para a sua busca`
    }
  };

  return (
    <div>
      <div className={style.container}>
        <div className={style.resultCountTitle}>
          {composeResultado(totalResults)}
        </div>
        <div className={style.questions}>
          {
            questoes.map((questao, id) => (
              <div
                key={id}
                onClick={() => {
                  goToQuestion(questao.id);
                }}
                className={style.question}
              >
                <div className={style.questionTitle}>
                  {`Questão ${questao.numero} - ${questao.prova.areaAvaliacao.nome} - ${questao.prova.ano}`}
                </div>
                <div className={style.test}>
                  <Stack direction='row' spacing={1}>
                    <Chip label={questao.parteProva === 'G' ? 'Geral' : 'Específica'} size='small' className={style.questionPart} />
                    <Chip label={questao.tipo === 'O' ? 'Objetiva' : 'Discursiva'} size='small' className={style.questionType} />
                  </Stack>
                </div>
                <div className={style.questionDescription}>
                  <Typography variant='body2'>
                    {composeConteudoDetalhado(questao.conteudoDetalhado, 120)}
                  </Typography>
                </div>
                <div className={style.questionSeeMore}>Ver mais</div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};
