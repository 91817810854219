import CursoController from './CursoController';
import ProvaController from './ProvaController';

export default class SearchController {
  #cursoController;
  #provaController;

  constructor() {
    this.#cursoController = new CursoController();
    this.#provaController = new ProvaController();
  }

  async getOpcoesCursos() {
    return await this.#cursoController.getOpcoesCursos();
  }

  async getOpcoesAnos(opcaoCurso) {
    return await this.#provaController.getOpcoesAnos(opcaoCurso);
  }
};
