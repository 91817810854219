import { useMemo } from 'react';
import { isEqual } from 'lodash';

import { useQuery } from './useQuery';

export const useQueryParams = (params) => {
  const query = useQuery();

  const iteratee = ([key, value]) => {
    value = isEqual(value, 'null') ? null : value;
    return [key, value];
  };

  const parseQueryParams = (query, params) => {
    const entries = params.map(param => [param, query.get(param)]);
    return Object.fromEntries(entries.map(entry => iteratee(entry)));
  };

  return useMemo(() => {
    const queryParams = parseQueryParams(query, params);
    return queryParams;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
};
